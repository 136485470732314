import { SupportedListingEntities } from 'modules/Listings/types';
import { Link } from 'components/Link';
import { BreadcrumbItem, Breadcrumbs } from 'components/Breadcrumbs';
import { getAppId, getUserId } from 'modules/Listings/helpers/entityCardUtils';
import { isApp, Entity } from 'utils/typeguards/cfEntities';
import { ClarifaiLogoItem, ClarifaiTextItem } from './ClarifaiLogoItem';

export const FooterBreadcrumbs: React.FC<{ entity: SupportedListingEntities }> = ({ entity }) => {
  const isEntityApp = isApp(entity as Entity);
  const userId = getUserId(entity);
  const appId = getAppId(entity);

  return (
    <Breadcrumbs>
      <BreadcrumbItem>
        <Link href={`/${userId}`} aria-label={`View the ${userId} user space for this resource`}>
          <ClarifaiLogoItem userId={userId} />
        </Link>
      </BreadcrumbItem>
      {!isEntityApp ? (
        <BreadcrumbItem>
          <Link href={`/${userId}/${appId}`} aria-label={`View the ${appId} (app) for this resource`}>
            <ClarifaiTextItem text={appId} />
          </Link>
        </BreadcrumbItem>
      ) : null}
    </Breadcrumbs>
  );
};
