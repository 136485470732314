import NextLink from 'next/link';
import type { LinkProps } from 'next/link';

export interface ExtendedLinkProps extends React.PropsWithChildren<LinkProps> {
  className?: string;
  'data-testid'?: string | undefined;
  onClick?: React.MouseEventHandler;
  target?: '_self' | '_blank' | '_parent' | '_top';
  rel?: 'nofollow' | 'noreferrer' | 'noopener' | string;
}
export const Link = ({ children, className, target = '_self', onClick, rel, ...props }: ExtendedLinkProps): JSX.Element => (
  <NextLink passHref {...props}>
    <a
      rel={rel}
      target={target}
      onClick={onClick}
      className={className}
      data-testid={props['data-testid'] || ''}
      href={props.href ? String(props.href) : '#'}
    >
      {children}
    </a>
  </NextLink>
);
