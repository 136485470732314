import { useCallback, useMemo } from 'react';
import { Select, SelectOption } from 'components/Select/Select';
import { IconCheck, IconCloseRound } from 'components/Icons';
import { alphabeticalSort } from 'utils/collections';
import { baseTheme } from 'styles/utils';
import { TextTooltip } from 'components/Tooltip';
import { cx } from '@linaria/core';
import { Row, RowData } from '@tanstack/react-table';
import { selectFilterWrapper, selectElementClassName, tooltipCls, optionItemStyle } from './SelectFilter.styles';

type SelectFilterTypes = {
  filterValue: string;
  preFilteredRows: Row<RowData>[];
  columnId: string;
  setFilter: (value: string) => void;
  isMobileView?: boolean;
};

// custom filter UI for selecting
// renders unique option from a list
export const SelectFilter = ({ filterValue, preFilteredRows, columnId: id, setFilter, isMobileView }: SelectFilterTypes): JSX.Element => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const optionsList = new Set<string>();

    preFilteredRows.forEach((row: Row<RowData>) => optionsList.add(row.getValue(id) as string));
    return makeOptions(optionsList, filterValue);
  }, [id, preFilteredRows]);

  const onChangeHandler = useCallback((data) => {
    setFilter(data?.value || undefined);
  }, []);

  const renderTitle = (
    <TextTooltip arrowProps={{ className: 'darkArrow' }} showArrow text={filterValue} className={tooltipCls}>
      {`"${filterValue || ''}"`}
    </TextTooltip>
  );

  if (isMobileView) {
    return (
      <div className={selectFilterWrapper}>
        <Select aria-label="select filter" title={filterValue ?? 'All'} placeholder="All" onChange={onChangeHandler} options={options} />
      </div>
    );
  }

  return (
    <div className={selectFilterWrapper}>
      <Select
        title={filterValue ? renderTitle : 'All'}
        placeholder="All"
        onChange={onChangeHandler}
        options={options}
        selectElementClassName={selectElementClassName}
        aria-label="select filter"
        LeftIcon={
          filterValue && (
            <div className="clearIcon">
              <IconCloseRound
                size={16}
                color={baseTheme.light.colors.gray700}
                onClick={(event) => {
                  event.stopPropagation();
                  onChangeHandler(null);
                }}
                strokeWidth={2}
                tabIndex={0}
                role="button"
                aria-label="clear filter"
              />
            </div>
          )
        }
      />
    </div>
  );
};

const makeOptions = (optionsList: Set<string>, filterValue: string): SelectOption[] => {
  const results: SelectOption[] = [];

  optionsList.forEach((optionItem) =>
    results.push({
      name: optionItem,
      value: optionItem,
      content: (
        <div aria-label="button" className={cx(optionItemStyle, optionItem === filterValue && 'selected')}>
          {optionItem}
          {optionItem === filterValue && <IconCheck size={20} color={baseTheme.light.colors.primary600} />}
        </div>
      ),
    }),
  );

  return alphabeticalSort(results);
};
