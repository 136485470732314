import { cx } from '@linaria/core';
import { makeUserFullName } from 'utils/users';
import { useCurrentUserId, useSwitchUserAccount } from 'modules/Auth/hooks';
import { useAuthState } from 'modules/Auth/AuthContext';
import { flex } from 'styles/utils';
import { Button } from 'components/Button';
import { desktopAccountSelector } from 'components/Navbar/Navbar.styles';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useCallback } from 'react';
import { Account, AccountProps } from '../Account';
import { PopoverSectionTitle } from '../PopoverLayout';
import { accountSelector, accountDefault } from './styles';
import { CreateNewOrgButton } from './CreateNewOrgButton';

export interface AccountSelectorProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  viewport?: 'desktop' | 'mobile';
  withSectionTitle?: boolean;
  withTag?: AccountProps['withTag'];
  /**
   * If passed, then a log out button will be shown at the bottom of the AccountSelector
   * in desktop viewport only.
   */
  onLogout?: React.MouseEventHandler<HTMLButtonElement>;
}

export const AccountSelector: React.FC<AccountSelectorProps> = ({
  className,
  viewport,
  onClose,
  onLogout,
  withSectionTitle,
  withTag,
  ...rest
}): JSX.Element => {
  const currentUserId = useCurrentUserId();
  const { authData } = useAuthState();
  const { track } = useDataCollection();
  const switchUserAccount = useSwitchUserAccount();

  const handleLogoutWithTracking = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!onLogout) {
        return;
      }
      track('Signout Button Clicked', { source: 'navbar account selector', viewport });
      onLogout(event);
    },
    [onLogout, track, viewport],
  );

  const loggedInUserFullName = authData ? makeUserFullName(authData) : '';
  const orgsList = authData?.organizations?.map(({ organization }) => organization) || [];

  return (
    <div
      data-testid="account-selector"
      className={cx(flex.init, flex.direction.column, withSectionTitle ? flex.gap[1] : flex.gap.quarter, className)}
      {...rest}
    >
      <section className={accountSelector}>
        {withSectionTitle && <PopoverSectionTitle>PERSONAL WORKSPACE</PopoverSectionTitle>}
        <Account
          type="user"
          id={authData?.user_id}
          name={loggedInUserFullName}
          withTag={withTag}
          iconSize={24}
          className={cx(!orgsList.length && accountDefault)}
          selected={authData?.user_id === currentUserId}
          onSelect={() => {
            switchUserAccount(authData?.user_id);
            onClose();
          }}
        />
      </section>

      <section className={accountSelector}>
        {withSectionTitle && <PopoverSectionTitle>ORGANIZATIONS</PopoverSectionTitle>}
        {orgsList.map((org) => (
          <Account
            type="org"
            key={org.id}
            id={org.id}
            name={org.name}
            withTag={withTag}
            iconSize={24}
            selected={org.id === currentUserId}
            onSelect={() => {
              switchUserAccount(org.id, true);
              onClose();
            }}
          />
        ))}

        <CreateNewOrgButton />
      </section>

      {onLogout && (
        <section className={desktopAccountSelector}>
          <Button className="fullWidth" variant="secondary" onClick={handleLogoutWithTracking}>
            Sign Out
          </Button>
        </section>
      )}
    </div>
  );
};
