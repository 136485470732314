import { Image } from 'components/Image';
import { wrapper, iconWrapper, userLetterIcon, textWrapper } from './ClarifaiLogoItem.styled';
export const ClarifaiTextItem = ({
  text
}: {
  text: string;
}): JSX.Element => {
  return <div className={textWrapper}>{text}</div>;
};
export const ClarifaiLogoItem = ({
  userId
}: {
  userId: string;
}): JSX.Element => <div role="presentation" className={wrapper}>
    {userId === 'clarifai' ? <div className={iconWrapper}>
        <Image src="/clarifai-icon.svg" height={32} width={32} />
      </div> : <div className={iconWrapper}>
        <div className={userLetterIcon}>{userId[0].toUpperCase()}</div>
      </div>}

    <ClarifaiTextItem text={userId} />
  </div>;