import { useCurrentUserId, useIsOrgAccount } from 'modules/Auth/hooks';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { useOrgHasTeamRole } from 'modules/Organizations/hooks';
import { ORGANIZATION_ROLE_ID_ADMIN, ORGANIZATION_ROLE_ID_MEMBER_MIN, ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER } from 'modules/Organizations/constants';
import { useCallback } from 'react';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';

interface AccountLink {
  text: string;
  url?: string;
  badge?: string;
  externalLink?: boolean;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface AccountLinkGroup {
  id: string;
  title?: string;
  links: AccountLink[];
}

interface GetAccountLinkGroupsFnArgs {
  userOrOrgId: string;
  /**
   * Callback to be called when the user clicks on the "Sign Out" button.
   */
  onLogout: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * If true, only the links that should be displayed in the desktop view will be returned.
   */
  desktopOnly?: boolean;
  /**
   * The role of the user in the organization.
   */
  orgRole?: CF.API.Organizations.Role;
  orgHasTeamRole?: boolean;
  featureFlags?: ReturnType<typeof useFeatureFlagsLD>;
}

type UseAccountPopoverLinksProps = Pick<GetAccountLinkGroupsFnArgs, 'onLogout' | 'desktopOnly'>;

export function useAccountPopoverLinks({ onLogout, ...props }: UseAccountPopoverLinksProps): AccountLinkGroup[] {
  const isOrg = useIsOrgAccount();
  const userOrOrgId = useCurrentUserId();
  const featureFlags = useFeatureFlagsLD();
  const org = useCurrentUserOrg();
  const orgHasTeamRole = useOrgHasTeamRole();
  const { track } = useDataCollection();

  const handleLogoutWithTracking = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track('Signout Button Clicked', { source: 'navbar menu', viewport: 'desktop' });
      onLogout(event);
    },
    [onLogout, track],
  );

  const getAccountLinkGroupsFn = isOrg ? orgAccountLinkGroups : personalAccountLinkGroups;
  const accountLinks = getAccountLinkGroupsFn({
    userOrOrgId,
    featureFlags,
    orgHasTeamRole,
    orgRole: org?.role,
    onLogout: handleLogoutWithTracking,
    ...props,
  });
  const nonEmptyGroups = accountLinks.filter((group) => group.links.length > 0);

  return nonEmptyGroups;
}

function personalAccountLinkGroups(props: GetAccountLinkGroupsFnArgs): AccountLinkGroup[] {
  return [
    {
      id: '0',
      title: 'Workspace',
      links: [
        ...(!props.desktopOnly
          ? [
              {
                text: 'Home',
                url: '/home',
              },
            ]
          : []),
        ...(!props.desktopOnly
          ? [
              {
                text: 'Projects',
                url: `/${props.userOrOrgId}`,
              },
            ]
          : []),
        ...(!props.desktopOnly
          ? [
              {
                text: 'Community',
                url: `/explore`,
              },
            ]
          : []),
        ...(props.featureFlags?.modelPlaygroundLDFF && !props.desktopOnly
          ? [
              {
                text: 'Playground',
                url: `/playground`,
              },
            ]
          : []),
        ...(props.featureFlags?.nodePoolDeploymentsLDFF && !props.desktopOnly
          ? [
              {
                text: 'Compute',
                url: `/settings/compute`,
              },
            ]
          : []),
        ...(props.featureFlags?.controlCenter && !props.desktopOnly
          ? [
              {
                text: 'Control Center',
                url: '/control-center',
              },
            ]
          : []),
        {
          text: 'Collaborations',
          url: `/${props.userOrOrgId}/collaborations`,
        },
      ],
    },
    {
      id: '1',
      title: 'Settings',
      links: [
        {
          text: 'Account',
          url: '/settings',
        },
        {
          text: 'Security',
          url: '/settings/security',
        },
        {
          text: 'Billing',
          url: '/settings/billing',
        },
        {
          text: 'Usage',
          url: '/settings/usage',
        },
        {
          text: 'Sign Out',
          handleClick: props.onLogout,
        },
      ],
    },
  ];
}

function orgAccountLinkGroups(props: GetAccountLinkGroupsFnArgs): AccountLinkGroup[] {
  const isAdmin = props.orgRole?.id === ORGANIZATION_ROLE_ID_ADMIN;
  const isAdminOrMember = props.orgRole?.id !== ORGANIZATION_ROLE_ID_MEMBER_MIN;
  const isFinanicalManager = props.orgRole?.id === ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER;

  return [
    {
      id: '0',
      title: 'Organization',
      links: [
        ...(!props.desktopOnly
          ? [
              {
                text: 'Home',
                url: '/home',
              },
            ]
          : []),
        ...(!props.desktopOnly
          ? [
              {
                text: 'Projects',
                url: `/${props.userOrOrgId}`,
              },
            ]
          : []),
        ...(!props.desktopOnly
          ? [
              {
                text: 'Community',
                url: `/explore`,
              },
            ]
          : []),
        ...(props.featureFlags?.modelPlaygroundLDFF && !props.desktopOnly
          ? [
              {
                text: 'Playground',
                url: `/playground`,
              },
            ]
          : []),
        ...(props.featureFlags?.nodePoolDeploymentsLDFF && !props.desktopOnly
          ? [
              {
                text: 'Compute',
                url: `/settings/compute`,
              },
            ]
          : []),
        ...(props.featureFlags?.controlCenter && isAdmin && !props.desktopOnly
          ? [
              {
                text: 'Control Center',
                url: '/control-center',
              },
            ]
          : []),
      ],
    },
    {
      id: '1',
      title: 'Settings',
      links: [
        {
          text: 'Account',
          url: '/settings/organization',
        },
        ...(isAdmin
          ? [
              {
                text: 'Security',
                url: '/settings/organization/security',
              },
            ]
          : []),
        ...(isAdmin || isFinanicalManager
          ? [
              {
                text: 'Billing',
                url: '/settings/billing',
              },
            ]
          : []),
        ...(isAdminOrMember
          ? [
              {
                text: 'Members',
                url: '/settings/organization/members',
              },
            ]
          : []),
        ...(isAdminOrMember && props.orgHasTeamRole
          ? [
              {
                text: 'Teams',
                url: '/settings/organization/teams',
              },
            ]
          : []),
        {
          text: 'Sign Out',
          handleClick: props.onLogout,
        },
      ],
    },
  ];
}
